/* global DocumentTouch */
// Import everything from autoload
// eslint-disable-next-line import/no-unresolved, import/extensions
//import './vendor/*';
// eslint-disable-next-line import/no-unresolved, import/extensions
//import '../../modules/**/assets/**/*.js';
jQuery(function () {
  //Dynamic copyright js style
  var date = new Date().getFullYear();
  $('.Date').text(date); //Scroll effect

  $(window).scroll(function () {
    if ($(this).scrollTop() >= 1) {
      $('.Header').removeClass('Header-inactive');
      $('.Hero-logo').addClass('Hero-logo-inactive');
    } else {
      $('.Header').addClass('Header-inactive');
      $('.Hero-logo').removeClass('Hero-logo-inactive');
    }
  });
  $(window).scroll();
});